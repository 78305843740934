<template>
  <v-dialog v-model="dialog" max-width="1600px">
    <v-card>
      <v-card-title>
        Preview landing Page
      </v-card-title>
      <v-card-text>
        <v-row justify="center" align="center">
          <v-col cols="12">
            <v-row justify="center">
              <v-col cols="auto">
                <v-avatar size="80px" color="surface-variant">
                  <v-img width="80px" aspect-ratio="16/9" cover :src="dataAccount.logo"></v-img>
                </v-avatar>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <v-img :src="dataAccount.banner" style="width: 100%; max-height: 377.63px" />
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <v-row justify="center">
                  <v-col cols="12" md="6">
                    <v-row>
                      <v-col cols="12">
                        <v-row>
                          <v-col cols="12" md="4" class="mt-0 mt-md-1">
                            <v-autocomplete label="Estados" outlined class="no-icon-autocomplete" disabled></v-autocomplete>
                          </v-col>
                          <v-col cols="12" md="4" class="mt-0 mt-md-1">
                            <v-autocomplete label="Cidades" outlined class="no-icon-autocomplete" disabled></v-autocomplete>
                          </v-col>
                          <v-col cols="auto" class="mt-0 mt-md-1">
                            <v-btn
                              class="remove-disabled-style"
                              :style="{
                                'background-color': color.primary,
                                color: color.textColor,
                                width: '60px',
                                height: '55px',
                                padding: '8px, 12px, 8px, 12px',
                                'border-radius': '12px',
                                gap: '8px'
                              }"
                            >
                              <v-icon size="40">mdi-magnify</v-icon>
                            </v-btn>
                          </v-col>
                        </v-row>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
                <v-row justify="center">
                  <v-col cols="12" md="9">
                    <v-row>
                      <v-col v-for="item in 4" :key="item" cols="12" md="6">
                        <v-card class="border-card">
                          <v-row class="px-12">
                            <v-col>
                              <v-row class="py-3">
                                <v-col>
                                  <v-avatar size="45px" color="grey">
                                    <v-img style="width: 100%; height: auto" :src="dataAccount.logo" />
                                  </v-avatar>
                                  <span class="ml-2 title">
                                    Conta de teste
                                  </span>
                                </v-col>
                              </v-row>
                              <v-row no-gutters class="ml-2">
                                <v-col>
                                  <p class="mb-2"><v-icon size="19">mdi-whatsapp</v-icon> (41) 99999-9999</p>
                                  <p class="mb-2"><v-icon size="19">mdi-phone</v-icon> (41) 99999-9999</p>
                                  <p><v-icon size="19">mdi-email</v-icon> comercial.teste@teste.com</p>
                                </v-col>
                              </v-row>
                            </v-col>
                          </v-row>
                          <v-card-actions class="px-0 py-0">
                            <v-row no-gutters>
                              <v-col>
                                <div
                                  class="mt-1"
                                  :style="{
                                    'background-color': color.primary,
                                    color: color.textColor,
                                    'border-bottom-left-radius': '11px',
                                    'border-bottom-right-radius': '11px'
                                  }"
                                >
                                  <v-row justify="center">
                                    <v-col class="mt-n1" cols="auto">
                                      <span :style="{ fontSize: '16px' }"> Comprar </span>
                                      <v-avatar :style="{ 'background-color': color.primaryLight }" size="20">
                                        <v-icon size="20" :color="color.textColor">mdi-chevron-right</v-icon>
                                      </v-avatar>
                                    </v-col>
                                  </v-row>
                                </div>
                              </v-col>
                            </v-row>
                          </v-card-actions>
                        </v-card>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-btn color="primary" @click="fecharDialog">Fechar</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    dataAccount: Object,
    value: {
      type: Boolean,
      default: false
    },
    color: {
      type: Object
    }
  },
  data() {
    return {
      dialog: this.value,
      stateSelected: null,
      citiesSelected: null,
      state: [],
      cities: [],
      disabled: false
    }
  },
  watch: {
    value() {
      this.dialog = this.value
    },
    dialog() {
      this.$emit('input', this.dialog)
    }
  },
  methods: {
    fecharDialog() {
      this.dialog = false
    }
  }
}
</script>

<style lang="scss">
.rounded-button {
  border-radius: 30px !important;
}
.border-card {
  border-radius: 15px !important;
}
.title {
  font-size: 15px;
  font-weight: 500;
  line-height: 15px;
  letter-spacing: 0em;
  text-align: left;
}
.remove-disabled-style {
  pointer-events: none;
  opacity: 1;
  cursor: default;
}

.no-icon-autocomplete .v-icon {
  display: none !important;
}
</style>
