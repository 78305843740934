var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"max-width":"1600px"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-card-title',[_vm._v(" Preview landing Page ")]),_c('v-card-text',[_c('v-row',{attrs:{"justify":"center","align":"center"}},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-row',{attrs:{"justify":"center"}},[_c('v-col',{attrs:{"cols":"auto"}},[_c('v-avatar',{attrs:{"size":"80px","color":"surface-variant"}},[_c('v-img',{attrs:{"width":"80px","aspect-ratio":"16/9","cover":"","src":_vm.dataAccount.logo}})],1)],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-img',{staticStyle:{"width":"100%","max-height":"377.63px"},attrs:{"src":_vm.dataAccount.banner}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-row',{attrs:{"justify":"center"}},[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-row',[_c('v-col',{staticClass:"mt-0 mt-md-1",attrs:{"cols":"12","md":"4"}},[_c('v-autocomplete',{staticClass:"no-icon-autocomplete",attrs:{"label":"Estados","outlined":"","disabled":""}})],1),_c('v-col',{staticClass:"mt-0 mt-md-1",attrs:{"cols":"12","md":"4"}},[_c('v-autocomplete',{staticClass:"no-icon-autocomplete",attrs:{"label":"Cidades","outlined":"","disabled":""}})],1),_c('v-col',{staticClass:"mt-0 mt-md-1",attrs:{"cols":"auto"}},[_c('v-btn',{staticClass:"remove-disabled-style",style:({
                              'background-color': _vm.color.primary,
                              color: _vm.color.textColor,
                              width: '60px',
                              height: '55px',
                              padding: '8px, 12px, 8px, 12px',
                              'border-radius': '12px',
                              gap: '8px'
                            })},[_c('v-icon',{attrs:{"size":"40"}},[_vm._v("mdi-magnify")])],1)],1)],1)],1)],1)],1)],1),_c('v-row',{attrs:{"justify":"center"}},[_c('v-col',{attrs:{"cols":"12","md":"9"}},[_c('v-row',_vm._l((4),function(item){return _c('v-col',{key:item,attrs:{"cols":"12","md":"6"}},[_c('v-card',{staticClass:"border-card"},[_c('v-row',{staticClass:"px-12"},[_c('v-col',[_c('v-row',{staticClass:"py-3"},[_c('v-col',[_c('v-avatar',{attrs:{"size":"45px","color":"grey"}},[_c('v-img',{staticStyle:{"width":"100%","height":"auto"},attrs:{"src":_vm.dataAccount.logo}})],1),_c('span',{staticClass:"ml-2 title"},[_vm._v(" Conta de teste ")])],1)],1),_c('v-row',{staticClass:"ml-2",attrs:{"no-gutters":""}},[_c('v-col',[_c('p',{staticClass:"mb-2"},[_c('v-icon',{attrs:{"size":"19"}},[_vm._v("mdi-whatsapp")]),_vm._v(" (41) 99999-9999")],1),_c('p',{staticClass:"mb-2"},[_c('v-icon',{attrs:{"size":"19"}},[_vm._v("mdi-phone")]),_vm._v(" (41) 99999-9999")],1),_c('p',[_c('v-icon',{attrs:{"size":"19"}},[_vm._v("mdi-email")]),_vm._v(" comercial.teste@teste.com")],1)])],1)],1)],1),_c('v-card-actions',{staticClass:"px-0 py-0"},[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',[_c('div',{staticClass:"mt-1",style:({
                                  'background-color': _vm.color.primary,
                                  color: _vm.color.textColor,
                                  'border-bottom-left-radius': '11px',
                                  'border-bottom-right-radius': '11px'
                                })},[_c('v-row',{attrs:{"justify":"center"}},[_c('v-col',{staticClass:"mt-n1",attrs:{"cols":"auto"}},[_c('span',{style:({ fontSize: '16px' })},[_vm._v(" Comprar ")]),_c('v-avatar',{style:({ 'background-color': _vm.color.primaryLight }),attrs:{"size":"20"}},[_c('v-icon',{attrs:{"size":"20","color":_vm.color.textColor}},[_vm._v("mdi-chevron-right")])],1)],1)],1)],1)])],1)],1)],1)],1)}),1)],1)],1)],1)],1)],1)],1)],1),_c('v-card-actions',[_c('v-btn',{attrs:{"color":"primary"},on:{"click":_vm.fecharDialog}},[_vm._v("Fechar")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }